import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { HttpComplement } from 'src/common/functions/http-complement';
import { MessagesAlerts } from 'src/common/functions/messages-alerts';
import { ModuleBackend } from 'src/enums/modules-backend.enum';

interface JwtResponseI {
  statusCode: number,
  data: {
    email: string,
    id: number,
    token: string
  }
}

interface UserLoginI {
  email: string,
  password: string
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  API_URI: string = environment.API_URL
  success: Boolean = false
  message: string = '';
  private user$ = new BehaviorSubject<any | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private headerComplement: HttpComplement,
    private messagesAlerts: MessagesAlerts,
  ) { }

  get userAuthenticated$(): Observable<any> {
    return this.user$.asObservable();
  }

  setUserAuthenticated(user: any) {
    this.user$.next(user);
  }

  login(module: ModuleBackend, authData: UserLoginI): Observable<JwtResponseI | void> {
    return this.http.post<JwtResponseI>(`${this.API_URI}/${module}/login`, authData)
      .pipe(
        map((res: JwtResponseI) => {
          this.setUserAuthenticated(res.data);
          return res;
        })
      );
  }

  /**
 * Logs the user out and invalidates the session token.
 *
 * @return {Observable<JwtResponseI | boolean>} The response from the server, indicating whether the logout was successful or not.
 */
  logout(module: ModuleBackend): Observable<JwtResponseI | boolean> {
    return this.http.post(
      `${this.API_URI}/${module}/logout`, {},
      this.headerComplement.complementHeader()
    )
      .pipe(
        map(
          (res: any) => {
            localStorage.removeItem('token');
            localStorage.removeItem('menu');
            return true;
          }
        ),
        catchError(
          (err) => { //Actualmente devuelve un string "cerrar sesion 1"
            localStorage.removeItem('token');
            localStorage.removeItem('menu');
            this.router.navigate(['/login']);
            return of(false);
          }
        )
      )
  }

  public isAuthenticated(): Observable<boolean | UrlTree> | any {
    if (localStorage.getItem('token') != null) {
      return this.http.get(
        `${this.API_URI}/${ModuleBackend.AUTH}/check_status`,
        this.headerComplement.complementHeader()
      )
        .pipe(
          map(
            (res: any) => {
              if (res.data.firstAccess) {
                this.router.navigate(['/validate-access/reset-password']);
                return false;
              } else {
                return true;
              }
            }
          ),
          catchError(
            (err) => {
              localStorage.removeItem('token');
              localStorage.removeItem('menu');
              this.router.navigate(['/']);
              return of(false);
            }
          )
        )
    } else {
      // Catched in checkguard
      return of(true);
    }
  }
  public getUserAutenticated(): Observable<any | UrlTree> | any {
    return this.http.get(
      `${this.API_URI}/${ModuleBackend.AUTH}/check_status`,
      this.headerComplement.complementHeader()
    )
      .pipe(
        map(
          (res: any) => {
            this.setUserAuthenticated(res.data);
            res.data.menu.roles = res.data.roles;
            localStorage.setItem('menu', JSON.stringify(res.data.menu));
            return res.data;
          }
        )
      )
  }


  public changePassword(module: ModuleBackend, data: any): Observable<boolean | UrlTree> | any {
    return this.http.post(`
    ${this.API_URI}/${module}/reset_password`,
      data,
      this.headerComplement.complementHeader()
    );
  }

  public updateProfile(module: ModuleBackend, data: any): Observable<boolean | UrlTree> | any {
    return this.http.post(`
    ${this.API_URI}/${module}/update_profile`,
      data,
      this.headerComplement.complementHeader()
    );
  }
}
