import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ValidateAuthValidateAccessGuard implements CanActivate {

  API_URI: string = environment.API_URL

  constructor(
    public router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {

    if (localStorage.getItem('token') != null) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }


}
