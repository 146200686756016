export enum ModuleBackend {
  USER = 'users',
  AUTH = 'auth',
  MODULE = "modules",
  ROLES = "roles",
  CLIENTS = "client",
  CLIENTS_USERS = "user-sae",
  PROPERTY = "property",
  COMPOUND = "compound",
  REQUEST_CLIENTS = "request-client",
  PLANS = "plan",
  PLAN_CLIENTS = "plan_clients",
  GET_PLAN_CLIENT = "plan_clients",
  CHANGE_PLAN_CLIENT = "plan_clients/change_plan_client",
  DELETE_PLAN_CLIENT = "plan_clients/remove_with_client",
  SAVE_OR_UPDATE_PLAN_CLIENT = "plan_clients/Create",
  PAYMENT_METHOD = "payments-type",
  PAYMENTS = "payments",
  BILLS = "bills",
  INVOICES = "invoices",
  SERVICES = "services",
  TABLAS_TIPO = "table-types/all",
  CONSULTANCY = "consultancy",
  CONTACT = "contact",
  CURRENCIES = 'currencies',
  NOTIFICATION = 'notification',
  PUSH_NOTIFICATION = 'push-notification',
  PUSH_NOTIFICATION_CLIENT = 'push-notification-client',
  SYSTEM_MODULES = "module_groups",
  AUXILIARY_TYPES = "type-auxiliary",
  PLAN_ACCOUNTS = 'accounting-accounts'
}
