import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})

export class HttpComplement {

    complementHeaderRaw(): HttpHeaders { return this.complementHeader().headers; }

    complementHeader(): any {
        let options = { headers: new HttpHeaders(this.getToken()) };
        return options;
    }

    completeHeaderSendFiles(): any {
        const token = localStorage.getItem('token');
        let options = { headers: new HttpHeaders({
            'authorization': `bearer ${token}`
        })};
        return options;
        
    }

    getToken(): any {
        const token = localStorage.getItem('token');
        return ({
            'Content-Type': 'application/json',
            'authorization': `bearer ${token}`
        });
    }
}