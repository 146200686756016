import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CheckLoginGuard } from 'src/guards/check-login.guard';
import { CheckAuthenticatedGuard } from 'src/guards/check-authenticated.guard';
import { ValidateAuthValidateAccessGuard } from 'src/guards/check-auth-validate-access.guard';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule),
    canActivate: [CheckLoginGuard],
  },
  {
    path: 'validate-access',
    loadChildren: () => import('./validate-access/validate-access.module').then( m => m.ValidateAccessPageModule),
    canActivate: [ValidateAuthValidateAccessGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [CheckAuthenticatedGuard],
  },
  { path: '**', pathMatch: "full", redirectTo: "login" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
